<template>
  <section id="general-sheet-container">
    <b-container fluid class="m-0 p-0">
      <b-row>
        <b-col cols="10">
          <TabHeaderInfo
            title="Fichas personalizáveis para impressão"
            tooltip="Adicione fichas personalizadas para imprimir
            e ter a gestão dentro do dashboard cirúrgico"
          >
          </TabHeaderInfo>
        </b-col>
        <b-col cols="2" class="newButton">
          <b-button variant="primary" @click="openModal">
            Nova ficha
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col cols="12">
          <NoFilterResults
            class="text-align-center"
            v-if="!customSheets.length"
            message="Clique em Nova ficha e adicione uma ficha personalizada"
          />
        </b-col>
        <b-col
          v-for="sheet in customSheets"
          :key="sheet.id"
          md="3"
          sm="12"
          class="mb-2"
        >
          <Card
            :item="sheet"
            textLink="Editar Ficha"
            :update="updateGeneralSheetTemplateActive"
            :delete="deleteGeneralSheetTemplate"
            :edit="openModal"
          />
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="mb-3 m-0 p-0">
      <TabHeaderInfo
        title="Fichas prontas"
        tooltip="Utilize fichas prontas e preencha pelo
        próprio sistema"
        tooltipId="tooltip-general-sheet"
      />
      <b-row>
        <b-col
          md="3"
          sm="12"
          class="mt-1"
          v-for="sheet in defaultSheets"
          :key="sheet.id"
        >
          <Card
            :item="sheet"
            textLink="Editar Ficha"
            :update="updateGeneralSheetTemplateActive"
            :canEdit="false"
            :canDelete="false"
          />
        </b-col>
      </b-row>
    </b-container>

    <GeneralSheetTemplateModal
      :sheet="sheet"
      :clinicId="clinic.id"
      @createGeneralSheetTemplate="createGeneralSheetTemplate"
      @updateGeneralSheetTemplate="updateGeneralSheetTemplateArray"
      @closeModal="clearSheet"
    />
  </section>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'GeneralSheet',
  components: {
    TabHeaderInfo: () => import('@/components/General/TabHeaderInfo'),
    Card: () => import('@/components/SurgeryCenter/Configuration/Card'),
    GeneralSheetTemplateModal: () =>
      import(
        '@/components/SurgeryCenter/Configuration/Modals/GeneralSheetTemplateModal'
      ),
    NoFilterResults: () => import('@/components/General/noFilterResults')
  },
  data: () => ({
    clinic: getCurrentClinic(),
    sheets: [],
    sheet: {}
  }),
  computed: {
    defaultSheets() {
      return this.sheets.filter(sheet => !sheet.can_edit)
    },
    customSheets() {
      return this.sheets.filter(sheet => sheet.can_edit)
    }
  },
  async created() {
    await this.getGeneralSheetTemplates()
  },
  methods: {
    async getGeneralSheetTemplates() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.getGeneralSheetTemplates(this.clinic.id)
        this.sheets = data.map(sheet => ({
          ...sheet,
          can_edit: Boolean(sheet.can_edit),
          active: Boolean(sheet.active)
        }))
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    createGeneralSheetTemplate(item) {
      this.sheets.push({
        ...item,
        can_edit: true,
        active: true
      })
    },
    async updateGeneralSheetTemplateActive(sheet) {
      const isLoading = this.$loading.show()
      try {
        await this.api.updateGeneralSheetTemplateActive(sheet.id, {
          ...sheet
        })
        if (sheet.active) {
          this.$toast.success('Ficha ativada com sucesso!')
        } else {
          this.$toast.success('Ficha desativada com sucesso!')
        }
        this.updateGeneralSheetTemplateArray(sheet)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    updateGeneralSheetTemplateArray(sheet) {
      const index = this.sheets.findIndex(item => item.id === sheet.id)
      this.$set(this.sheets, index, {
        ...sheet
      })
    },
    async deleteGeneralSheetTemplate(id) {
      const isLoading = this.$loading.show()
      try {
        await this.api.deleteGeneralSheetTemplate(id)
        const index = this.sheets.findIndex(item => item.id === id)
        this.sheets.splice(index, 1)
        this.$toast.success('Ficha removida com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    openModal({ item }) {
      if (item) {
        this.sheet = item
      }
      this.$bvModal.show('general-sheet-template-modal')
    },
    clearSheet() {
      this.sheet = {}
    }
  }
}
</script>
<style lang="scss" scoped>
.newButton {
  padding: 20px 0 30px 0;
  justify-content: flex-end;
  display: flex;
}
</style>
